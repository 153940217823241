<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Library books
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Library books
                </div>
              </div>
              <div class="breadcrumb-right">
                <b-dropdown v-if="checkIsAccessible('import-export', 'export-library-book')" size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <a @click="exportToCsv()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                        <span class="navi-text">Export (CSV)</span>
                      </a>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>

              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="3">
                  <v-text-field dense id="title" label="Book name, author, publication, editor" outlined type="text" clearable
                                v-model="search.name"   v-on:keyup.enter="searchBook()" ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                      v-on:keyup.enter="searchBook()"
                      label="Program"
                      :items="programs"
                      item-value="id"
                      item-text="title_text"
                      outlined
                      dense
                      v-model="search.program_id"
                      clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select :items="statuses" dense item-text="title" item-value="value"  v-on:keyup.enter="searchBook()" clearable
                            outlined v-model="search.status">
                  </v-select>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="text-right">
                  <v-btn
                      @click="searchBook()"
                      class="btn btn-primary btn-search "
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column" style="max-width: 350px !important; white-space: pre-wrap;">Name</th>
                  <th class="wrap-column">Info</th>
                  <th class="wrap-column">Total stock</th>
                  <th class="wrap-column">Total issued</th>
                  <th>Status</th>
                  <th class="pr-3 text-center">Action</th>
<!--                  <th class="text-center">Action</th>-->
                </tr>
                </thead>
                <tbody class="text-left" v-if="books.length>0">
                <tr v-for="(item, index) of books" :key="index">
                  <td class="px-3 wrap-column" style="max-width: 350px !important; white-space: pre-wrap;">
                    <a  class="text-primary font-weight-bolder text-hover-primary mb-1" href="#" >
                      {{ item.title }}
                    </a>&nbsp;
                    <div class="text-secondary font-weight-medium">
                      <b>Year:</b> {{ item.year_of_publication ? item.year_of_publication : 'N/A' }}
                    </div>
                    <div class="text-secondary font-weight-medium">
                      <b>Edition:</b> {{ item.edition ? item.edition : 'N/A' }}
                    </div>
                  </td>

                  <td class="wrap-column">
                    <div class="text-secondary">
                      <b>Author:</b> {{ item.author ? item.author : 'N/A' }}
                    </div>
                    <div class="text-secondary">
                      <b>Publisher:</b> {{ item.publisher ? item.publisher : 'N/A' }}
                    </div>
                    <div class="text-secondary">
                      <b>Publication:</b> {{ item.publication ? item.publication : 'N/A'  }}
                    </div>
                    <div class="text-secondary">
                      <strong>ISBN: </strong> {{ item.isbn_no ? item.isbn_no : 'N/A' }}
                    </div>
                    <div class="text-secondary">
                      <strong>Classification no.</strong> 
                      {{ item.dewey_decimal_class_title ? item.dewey_decimal_class_title : 'N/A' }}
                    </div>
                  </td>
                  
                  <td class="wrap-column">
                    {{ item.total_quantity }}
                  </td>

                  <td class="wrap-column">
                   <span :class="item.total_issued_count > 0?'text-success font-weight-bold':'text-muted'">
                        {{ item.total_issued_count }}
                      </span>
                  </td>
                  <td class="px-3 wrap-column" >
                          <span class="badge badge-success text-lg`"
                                v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                          >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                  </td>
                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text tag="div" class="navi-item"
                                           v-if="checkIsAccessible('download', 'book-history')">
                            <a @click="exportBookIssueHistory(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fas fa-file-csv"></i>
                                  </span>
                              <span class="navi-text">Download book history</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </template>
                  </td>
<!--                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        &lt;!&ndash;begin::Navigation&ndash;&gt;
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item"
                                           v-if="checkIsAccessible('library-book', 'edit')">
                            <a @click="openBookDetail(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">mdi-pencil</v-icon>
                                  </span>
                              <span class="navi-text">Edit</span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item"
                                           v-if="checkIsAccessible('library-book', 'delete')">
                            <a class="navi-link" @click.prevent="deleteBook(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">mdi-delete</v-icon>
                                  </span>
                              <span class="navi-text">Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </template>
                  </td>-->
                </tr>
                </tbody>
                <tbody class="text-left" v-if="books.length == 0">
                <tr>
                  <td class="px-3 text-center" colspan="7">
                    <strong>No data available to display.</strong>
                  </td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="books.length > 0"
                  :disabled="loading"
                  class="pull-right mt-7"
                  @input="getAllBooks"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import BookService from "@/core/services/library-book/book/BookService";
import ProgramService from "@/core/services/level/program/ProgramService";
const program=new ProgramService();
const book=new BookService();
export default {
  data(){
    return{
      programLoading:false,
      loading:false,
      search: {
        classification_no: '',
        title: '',
        author: '',
        status: 'active',
        program_id:null,
      },
      statuses: [
        {value: "active", title: "Active"},
        {value: "in_active", title: "Inactive"},
        {value: "in_stock", title: "In stock"},
        {value: "out_of_stock", title: "Out of stock"},
        {value: "issued", title: "Issued"},
        {value: "newly_added", title: "Newly added"},
      ],
      books: [],
      page: 1,
      total: null,
      perPage: null,
      programs:[],
    }
  },
  methods:{
    searchBook(){
      this.getAllBooks();
    },
    getAllBooks(){
      this.loading=true;
      book
          .getAllLibraryBooks(this.search, this.page)
          .then(response => {
            this.books = response.data.data
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(()=>{
            this.loading=false;
          });

    },
    exportBookIssueHistory(bookId){
      book
          .exportBookIssuedHistory(bookId)
          .then(response => {

          }).catch(error => {

      })
          .finally(() => {

          })
    },
    exportToCsv(){
      book
          .exportBooksToCsv(this.search)
          .then(response => {
            this.$snotify.success('Export added to queue. You will receive an email upon completion');
          })
          .catch((err) => {

          })
          .finally(()=>{

          });
    },
    getPrograms() {
      this.programLoading=true;
      program
          .all()
          .then(response => {
            this.programs = response.data;
           })
          .catch((err) => {

          })
          .finally(()=>{
            this.programLoading=false;
          });
    },
  },
  mounted() {
    this.getPrograms();
    this.getAllBooks();
  }
}
</script>